import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucChatbotModule, WucLinkModule, WucTextHighlightModule } from '@inshared/website-ui-components';
import { OutChatbotModule } from 'outshared-lib';
import { ChatbotComponent } from './chatbot.component';
import { ChatbotContainer } from './chatbot.container';

/** @deprecated - this is moved to libs */
@NgModule({
    imports: [
        CommonModule,
        WucChatbotModule,
        WucLinkModule,
        OutChatbotModule.forRoot({
            baseUrl: 'https://api.digitalcx.com',
            customerKey: 'achmea',
            projectKey: 'inshared',
            apiKey: '2332132d-297f-4d28-9360-3ee377dcfbd4',
            culture: 'nl',
        }),
        WucTextHighlightModule,
    ],
    declarations: [
        ChatbotContainer,
        ChatbotComponent,
    ],
    exports: [
        ChatbotContainer,
    ],
})
export class ChatbotModule { }
