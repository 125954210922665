import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigService } from '@inshared/shared/core';
import {
    WucChatbotAnswerInterface,
    WucChatbotDialogOptionInterface,
    WucChatbotEscalationChannelInterface,
    WucChatbotEscalationFeedbackInterface,
    WucChatbotEscalationInterface,
} from '@inshared/website-ui-components';
import {
    AuthenticationDataService,
    ChatbotAnswerInterface,
    NewModal,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { FaqRoutingEnum } from '../../../../core/enums/faq-routing.enum';
import { LivechatService } from '../../../../core/livechat/livechat.service';
import { InsChatbotService } from '../../../../shared/customer-service/chatbot/chatbot.service';
import { IsCustomerModal } from '../../../../shared/modals/is-customer';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot-container',
    templateUrl: './chatbot.container.html',
})
export class ChatbotContainer implements OnInit, OnDestroy {
    @Input() public question: string;
    @Input() public isDialog: boolean = false;
    @Input() public isMobile: boolean = false;
    @Input() public isTablet: boolean = false;
    @Input() public maxHeight: string;
    @Input() public scrollTarget: 'list' | 'input' = 'list';

    public authenticated$: Observable<boolean> = this.authenticationDataService.isLoggedIn$();
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    public subscriptions: Subscription = new Subscription();
    public newQuestion$: ReplaySubject<string> = new ReplaySubject<string>();
    public newAnswer$: ReplaySubject<WucChatbotAnswerInterface> = new ReplaySubject<WucChatbotAnswerInterface>();

    public escalation?: WucChatbotEscalationInterface;

    private excludeQueryParamQuestions: string[] = ['ikweethetkentekenniet'];
    private questionRequest$: Subject<{ question: string; dialogPath?: string }> = new Subject<{
        question: string;
        dialogPath?: string;
    }>();

    // eslint-disable-next-line no-useless-constructor
    constructor(
        private chatbotService: InsChatbotService,
        private router: Router,
        private route: ActivatedRoute,
        private readonly relationDataService: RelationDataService,
        private livechatService: LivechatService,
        private newModal: NewModal,
        private readonly authenticationDataService: AuthenticationDataService,
        private readonly appConfigService: AppConfigService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.questionRequest$
                .asObservable()
                .pipe(
                    switchMap(
                        (question: { question: string; dialogPath?: string }): Observable<WucChatbotAnswerInterface> =>
                            this.chatbotService.askQuestion$(question.question, question.dialogPath)
                    ),
                    delay(1000)
                )
                .subscribe((response: ChatbotAnswerInterface): void => {
                    this.handleAnswer(response);
                })
        );

        this.subscriptions.add(
            this.route.queryParams.subscribe((params: Params): void => {
                if (params.q && !this.excludeQueryParamQuestions.includes(params.q)) {
                    this.onQuestion(params.q);
                }
            })
        );

        if (this.question) {
            setTimeout((): void => this.onQuestion(this.question));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onQuestion(question: string, dialogPath?: string): void {
        this.escalation = null;
        this.questionRequest$.next({ question, dialogPath });
        this.newQuestion$.next(question);
    }

    public onDialogOption(dialogOption: WucChatbotDialogOptionInterface): void {
        this.onQuestion(dialogOption.text, dialogOption.path);
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.chatbotService.sendFeedback(feedback);
    }

    public faqCategories: WucChatbotEscalationChannelInterface[] = [
        {
            icon: 'search',
            title: 'De 10 meestgestelde vragen',
            subTitle: '',
            url: FaqRoutingEnum.DeTienMeestGesteldeVragen,
        },
        {
            icon: 'report-damage',
            title: 'Schade',
            subTitle: '',
            url: FaqRoutingEnum.Schademelden,
        },
        {
            icon: 'add-document',
            title: 'Nieuwe verzekering',
            subTitle: '',
            url: FaqRoutingEnum.KlantWorden,
        },
        {
            icon: 'current-insurance',
            title: 'Lopende verzekering',
            subTitle: '',
            url: FaqRoutingEnum.LopendeVerzekeringGegevensWijzigen,
        },
        {
            icon: 'edit-insurance',
            title: 'Iets veranderd?',
            subTitle: '',
            url: FaqRoutingEnum.GegevensWijzigen,
        },
        {
            icon: 'annual-reward',
            title: 'Jaarbeloning',
            subTitle: '',
            url: FaqRoutingEnum.Jaarbeloning,
        },
    ];

    private handleAnswer(answer: ChatbotAnswerInterface): void {
        this.newAnswer$.next(answer);

        const channels: WucChatbotEscalationChannelInterface[] = [];

        const isLiveChatFeatureToggleOn: boolean = this.appConfigService.getConfig()?.enabledFeatures.liveChat || false;

        if (answer.escalation.testChat || (isLiveChatFeatureToggleOn && answer.escalation.showChat)) {
            channels.push({
                icon: 'chat',
                title: 'Via livechat',
                subTitle: 'Direct chatten met een medewerker',
                callback: (): void => this.showChat(),
            });
        }
        if (answer.escalation.showVerzekeringsmap) {
            channels.push({
                icon: 'current-insurance',
                title: 'Uw digitale Verzekeringsmap',
                subTitle: 'Daar kunt u (bijna) alles zelf!',
                routerLink: '/verzekeringsmap/mijn-verzekeringen',
            });
        }
        if (answer.escalation.showSchadeteamVerzekeringsmap) {
            channels.push({
                icon: 'current-insurance',
                title: 'Bekijk uw schademap',
                subTitle:
                    'In het onderdeel ‘Schades’ van uw digitale Verzekeringsmap ziet u ' +
                    'wat de status van uw schademelding is.',
                routerLink: '/verzekeringsmap/mijn-schades',
            });
        }
        if (answer.escalation.showFacebook) {
            channels.push({
                icon: 'facebook',
                title: 'Via Facebook',
                subTitle: 'We reageren binnen 1 werkdag.',
                url: 'https://www.facebook.com/messages/t/user:145298522187072',
            });
        }
        if (answer.escalation.showTwitter) {
            channels.push({
                icon: 'twitter',
                title: 'Via Twitter',
                subTitle: 'We reageren binnen 1 werkdag.',
                url: 'https://twitter.com/inshared',
            });
        }
        if (answer.escalation.showEmail) {
            channels.push({
                icon: 'mail',
                title: 'Via mail',
                subTitle: 'U krijgt binnen 2 werkdagen een reactie',
                callback: (): Promise<boolean> => this.router.navigate(['', { outlets: { modal: ['vraag'] } }]),
            });
        }
        if (answer.escalation.showSchadeteamMail) {
            channels.push({
                icon: 'mail',
                title: 'Stuur een e-mail naar ons schadeteam',
                subTitle:
                    'En sorry alvast. Normaal gesproken reageren we binnen 5 werkdagen, ' +
                    'maar door enorme drukte, kan het langer duren voordat u antwoord krijgt. ' +
                    'Alvast bedankt voor uw geduld en begrip!',
                callback: (): Promise<boolean> => this.router.navigate(['', { outlets: { modal: ['vraag', 'SCHD'] } }]),
            });
        }
        if (answer.escalation.showSchadeteamTel) {
            channels.push({
                icon: 'phone',
                subTitle: '+31 (0)71 364 17 62',
                title: 'Bel met ons schadeteam',
                url: 'tel:+31713641762',
            });
        }

        // No longer in use?
        // if (answer.escalation.showKlachtenmanMail) {}
        // if (answer.escalation.showSchadeteamChat) {}
        // if (answer.escalation.showSchadeteamFacebook) {}
        // if (answer.escalation.showSchadeteamTwitter) {}

        if (channels.length) {
            this.escalation = {
                usefulness: {
                    title: 'Heeft dit antwoord geholpen?',
                    buttonYesText: 'Ja, prima',
                    buttonNoText: 'Nee, helaas',
                },
                thanksText: 'Bedankt voor uw feedback',
                channels: [
                    {
                        title: 'Veelgestelde vragen',
                        items: this.faqCategories,
                    },
                    {
                        title: 'Contact opnemen',
                        items: channels,
                    },
                ],
            };
        } else {
            this.escalation = null;
        }
    }

    private showChat(): void {
        this.subscriptions.add(
            this.authenticated$.subscribe((isAuthenticated): void => {
                if (isAuthenticated) {
                    this.newModal.closeAll();
                    this.livechatService.init();
                } else {
                    this.newModal.closeAll();
                    this.newModal.open(IsCustomerModal);
                }
            })
        );
    }
}
