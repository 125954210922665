<wuc-chatbot
    [newQuestion$]="newQuestion$"
    [newAnswer$]="newAnswer$"
    buttonText="Verstuur"
    busyText="De chatbot typt..."
    placeholder="Typ hier uw onderwerp..."
    [maxNumberOfCharacters]="150"
    maxNumberOfCharactersText="(max 150 tekens)"
    (question)="onQuestion($event)"
    (dialogOption)="onDialogOption($event)"
    (feedback)="onFeedback($event)"
    [escalation]="escalation"
    [isMobile]="isMobile"
    [isTablet]="isTablet"
    [isDialog]="isDialog"
    [maxHeight]="maxHeight"
>
    <div
        slot="intro"
        class="ins-chatbot-entry__intro"
    >
        <ng-container *ngIf="!relation">Ik ben de chatbot. Waar gaat uw vraag over?</ng-container>

        <ng-container *ngIf="relation">
            Hallo,
            <ng-container [ngSwitch]="relation.gender_code">
                <ng-container *ngSwitchCase="'M'">meneer&#32;</ng-container>
                <ng-container *ngSwitchCase="'V'">mevrouw&#32;</ng-container>
            </ng-container>
            <span data-hj-suppress>{{ relation.middle_name_initials }} {{ relation.surname }}.</span>
            Ik ben de chatbot. Waar gaat uw vraag over?
        </ng-container>
    </div>
    <div slot="footer">
        <!-- prettier-ignore -->
        <div class="text text-center small">
            <wuc-text-highlight type="primary">Tip:</wuc-text-highlight>
            Wist u dat u alles bij ons zelf online kunt doen? Ga nu naar uw
            <wuc-link (clicked)="onNavigateAway()" [route]="'/' + appRoutesEnum.MyZone"
            >digitale Verzekeringsmap</wuc-link>!
        </div>
    </div>
</wuc-chatbot>
