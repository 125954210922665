<button class="btn btn--more" tabindex="0" title="Sluit" modal-close-btn (click)="close()">
    <span>Sluit</span>
    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#cross-closed"></use></svg>
</button>

<h3 modal-title>Bent u al klant bij inshared?</h3>

<div modal-content>
    <ins-login *ngIf="_showLogin" [loading]="loading$ | async" (login)="loginUser($event)"></ins-login>
    <div class="login-box__error" *ngIf="errors">
        <cyno-alert [errors]="errors"></cyno-alert>
    </div>
</div>

<div class="btn-group btn-group--spread">
    <button (click)="cancel()" class="btn btn--link">Nee, nog niet</button>
    <button (click)="showLogin()" *ngIf="!_showLogin" class="btn btn--primary" e2e="modal_submit">Inloggen</button>
</div>
