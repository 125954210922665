import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { LIVECHAT_CONFIGURATION } from '@app/core/livechat/livechat.configuration';
import {
    ChatContextInterface,
    ChatbotHistoryInterface,
    LivechatConfigurationInterface,
} from '@app/core/livechat/livechat.interfaces';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/** @deprecated - this is moved to libs */
@Injectable({
    providedIn: 'root',
})
export class LivechatService implements OnDestroy {
    constructor(
        private rendererFactory: RendererFactory2,
        private relationDataService: RelationDataService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LIVECHAT_CONFIGURATION) private liveChatConfiguration: LivechatConfigurationInterface,
    ) { }

    private chatWindowId = 'Microsoft_Omnichannel_LCWidget';
    private chatFrameWindowId = 'Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window';

    private renderer: Renderer2;
    private isInitialized: boolean = false;
    private subscription: Subscription = new Subscription();

    private relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    private chatBotHistory: ChatbotHistoryInterface[] = [];
    private _chatContext: ChatContextInterface;

    private setChatContext(value: ChatContextInterface): void {
        this._chatContext = { ...this._chatContext, ...value };
    }

    private contextProvider = (): ChatContextInterface => {
        return this._chatContext;
    };

    private handleLivechatReadyEvent = (): void => {
        this.openChat();
    };

    private setUserContext(): void {
        const user$ = this.relation$.pipe(filter((relation: RelationInterface) => !!relation)).subscribe((relation: RelationInterface): void => {
            this.setChatContext({
                cyno_relationnumber: { value: relation.relation_id.toString(), isDisplayable: true },
                cyno_emailaddress: { value: relation.primary_email_address.email_address, isDisplayable: true },
            });
        });
        this.subscription.add(user$);
    }

    private openChat(): void {
        Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(this.contextProvider);
        Microsoft.Omnichannel.LiveChatWidget.SDK.startChat({ inNewWindow: false });
        if (this.getIframe()) {
            this.renderer.setStyle(this.getIframe(), 'visibility', 'visible');
        }
    }

    private handleLivechatClosedEvent = (): void => {
        Microsoft.Omnichannel.LiveChatWidget.SDK.closeChat();
        if (this.getIframe()) {
            this.renderer.setStyle(this.getIframe(), 'visibility', 'hidden');
            this.removeChatbotHistory();
        }
    };

    private getIframe(): Element {
        return this.document.getElementById(this.chatFrameWindowId);
    }

    private addScript(): void {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        const script = this.renderer.createElement('script');

        this.renderer.setAttribute(script, 'type', 'text/javascript');
        this.renderer.setAttribute(script, 'id', this.chatWindowId);
        this.renderer.setAttribute(script, 'src', this.liveChatConfiguration.scriptSrc);
        this.renderer.setAttribute(script, 'data-color-override', '#F173AC');
        this.renderer.setAttribute(script, 'data-app-id', this.liveChatConfiguration.dataAppId);
        this.renderer.setAttribute(script, 'data-lcw-version', this.liveChatConfiguration.dataLcwVersion);
        this.renderer.setAttribute(script, 'data-org-id', this.liveChatConfiguration.dataOrgId);
        this.renderer.setAttribute(script, 'data-org-url', this.liveChatConfiguration.dataOrgUrl);
        this.renderer.setProperty(script, 'async', true);

        this.document.head.appendChild(script);
    }

    public init(): void {
        if (!this.isInitialized) {
            this.setUserContext();
            this.addScript();
            this.addListeners();
        } else {
            this.openChat();
        }
    }

    public clearChatContext(): void {
        this._chatContext = {};
    }

    public removeChatbotHistory(): void {
        this.chatBotHistory = [];
    }

    public setChatbotHistory(value: ChatbotHistoryInterface): void {
        this.chatBotHistory = [...this.chatBotHistory, value];
        this.clearChatContext();
        this.chatBotHistory.forEach((i, idx): void =>
            this.setChatContext({
                [`vraag ${idx + 1} ${i.question}`]: { value: i.answer, isDisplayable: true },
            })
        );
    }

    public addListeners(): void {
        this.renderer.listen('window', 'lcw:ready', this.handleLivechatReadyEvent);
        this.renderer.listen('window', 'lcw:onClose', this.handleLivechatClosedEvent);
        this.isInitialized = true;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.removeChatbotHistory();
    }
}
