import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppRoutesEnum } from '@app/routes';
import {
    WucChatbotAnswerInterface,
    WucChatbotDialogOptionInterface,
    WucChatbotEscalationFeedbackInterface,
    WucChatbotEscalationInterface,
} from '@inshared/website-ui-components';
import { RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-chatbot',
    templateUrl: './chatbot.component.html',
})
export class ChatbotComponent {
    @Input() public relation!: RelationInterface;
    @Input() public newQuestion$!: Observable<string>;
    @Input() public newAnswer$!: Observable<WucChatbotAnswerInterface>;
    @Input() public buttonText!: string;
    @Input() public isDialog: boolean = false;
    @Input() public isMobile: boolean = false;
    @Input() public isTablet: boolean = false;
    @Input() public scrollTarget: 'list' | 'input' = 'list';
    @Input() public maxHeight: string;
    @Input() public placeholder: string = '';
    @Input() public maxNumberOfCharactersText?: string;
    @Input() public maxNumberOfCharacters?: number;
    @Input() public escalation?: WucChatbotEscalationInterface;


    @Output() public readonly question: EventEmitter<string> = new EventEmitter<string>();
    @Output() public readonly dialogOption: EventEmitter<WucChatbotDialogOptionInterface>
        = new EventEmitter<WucChatbotDialogOptionInterface>();
    @Output() public readonly feedback: EventEmitter<WucChatbotEscalationFeedbackInterface> =
        new EventEmitter<WucChatbotEscalationFeedbackInterface>();
    @Output() public readonly navigateAway: EventEmitter<void> = new EventEmitter<void>();

    public appRoutesEnum = AppRoutesEnum;

    public onQuestion(question: string): void {
        this.question.emit(question);
    }

    public onDialogOption(dialogOption: WucChatbotDialogOptionInterface): void {
        this.dialogOption.emit(dialogOption);
    }

    public onFeedback(feedback: WucChatbotEscalationFeedbackInterface): void {
        this.feedback.emit(feedback);
    }

    public onNavigateAway(): void {
        this.navigateAway.emit();
    }
}
