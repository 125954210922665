import { Component } from '@angular/core';
import { LivechatService } from '@app/core/livechat/livechat.service';
import {
    AuthenticationActionService,
    AuthenticationDataService,
    ErrorInterface,
    LoginRequestInterface,
    NewModalRef as ModalRef
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-is-customer-modal',
    templateUrl: './is-customer.modal.html',
})
export class IsCustomerModal {
    private subscriptions: Subscription = new Subscription();

    public loading$: Observable<boolean> = this.authenticationDataService.loginPending$();
    public errors: ErrorInterface[];

    constructor(
        private modalRef: ModalRef<IsCustomerModal>,
        private livechatService: LivechatService,
        private authenticationActionService: AuthenticationActionService,
        private readonly authenticationDataService: AuthenticationDataService,

    ) { }

    public _showLogin: boolean = false;

    public cancel(): void {
        this.livechatService.init();
        this.modalRef.close();
    }

    public close(): void {
        this.modalRef.close();
    }

    public showLogin(): void {
        this._showLogin = true;
    }

    public loginUser(credentials: LoginRequestInterface): void {
        const loginSubscription = this.authenticationActionService.login$(credentials).subscribe({
            next: (): Promise<boolean> | void | string => this.handleSuccess(),
            error: (errors): void => this.handleErrors(errors),
        });

        this.subscriptions.add(loginSubscription);
    }

    private handleSuccess(): Promise<boolean> | void | string {
        this.livechatService.init();
        this.modalRef.close();
    }

    private handleErrors(errors: ErrorInterface[]): void {
        this.errors = errors;
    }
}
