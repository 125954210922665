<ins-chatbot
    [newQuestion$]="newQuestion$"
    [newAnswer$]="newAnswer$"
    [relation]="relation$ | async"
    [escalation]="escalation"
    [isDialog]="isDialog"
    [isMobile]="isMobile"
    [isTablet]="isTablet"
    [scrollTarget]="scrollTarget"
    [maxHeight]="maxHeight"
    (question)="onQuestion($event)"
    (dialogOption)="onDialogOption($event)"
    (feedback)="onFeedback($event)"
></ins-chatbot>
